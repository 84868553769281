import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    authService: AuthService;
    constructor(private _authService: AuthService,
        private tokenService: TokenService) {
        this.authService = _authService;
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authService.loggedIn.getValue()) {

            const token = this.tokenService.get();

            request = request.clone({
                setHeaders:
                {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
    }

    private handleAuthError(err: HttpErrorResponse) {
        if (err.status === 401 || err.status === 403) {
            console.error("Questa azione non ti è permessa", "Non autorizzato");
        }
        else if (err.status >= 500 && err.status < 600) {
            console.error("Errore interno al server");
        }
        return throwError(err);
    }

}
