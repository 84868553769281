import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ComunePickerComponent } from './comune-picker/comune-picker.component';



@NgModule({
  declarations: [FileUploadComponent, ComunePickerComponent,],
  imports: [
    CommonModule,
  ],
  exports: [
    FileUploadComponent,
    ComunePickerComponent,]
})
export class SharedModule { }
