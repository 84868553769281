import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { User } from '../models/user';
import { Comuni } from '../models/comuni';
import { Nazione } from '../models/nazione';



@Injectable()
export class JarwisService {
  private baseUrl = 'https://assunzioni.coopaccento.it/api';

  constructor(private http: HttpClient) { }

  signup(data): Promise<any> {
    return this.http.post(`${this.baseUrl}/signup`, this.toFormData(data)).toPromise()
  }

  delete(selectedIds: number[]) {
    return this.http.post(`${this.baseUrl}/users/delete`, { ids: selectedIds }).toPromise()
  }

  update(data): Promise<any> {
    return this.http.post(`${this.baseUrl}/users/update`, this.toFormData(data)).toPromise()
  }

  toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (value !== null) {
        if (Array.isArray(value)) //stiamo per inserire un array
        {
          for (let id = 1; id <= value.length; id++) {
            formData.append(`${key}_${id}`, value[id - 1]);

            /*risultato finale nella richiesta http:
              busta_paga_1: (binary)  
              busta_paga_2: (binary)
            */
          }
        }
        else //valore semplice
          formData.append(key, value);
      }

    }

    return formData;
  }

  login(data) {
    return this.http.post(`${this.baseUrl}/login`, data).pipe(
      map(v => {
        let json_user = v['user'];
        let object_user = new User().deserialize(json_user);
        v['user'] = object_user;
        return v;
      })
    )
  }

  sendPasswordResetLink(data) {
    return this.http.post(`${this.baseUrl}/sendPasswordResetLink`, data)
  }

  changePassword(data) {
    return this.http.post(`${this.baseUrl}/resetPassword`, data)
  }


  sendEmailVerify() {
    return this.http.get(`${this.baseUrl}/email/resend`).toPromise();
  }


  comuni(search: string = ''): Observable<Comuni[]> {
    return this.http.get<any[]>(`${this.baseUrl}/comuni`, { params: { search } }).pipe(
      map(val => val.map(object => new Comuni().deserialize(object))),
      share()
    );

  }

  nazioni(): Observable<Nazione[]> {
    return this.http.get<any[]>(`${this.baseUrl}/nazioni`).pipe(
      map(val => val.map(object => new Nazione().deserialize(object)))

    );

  }

  profileUser(): Promise<User> {
    return this.http.get(`${this.baseUrl}/profile`).pipe(
      map(val => new User().deserialize(val))
    ).toPromise();
  }

  exportCsv(start_from): Observable<User[]> {
    return this.http.get<any[]>(`${this.baseUrl}/exportcsv`,
      {
        params:
          { start_from }
      }
    ).pipe(
      share(),
      map(val => val.map(object => new User().deserialize(object)))

    );
  }


  getUsers(search): Observable<User[]> {
    return this.http.get<any[]>(`${this.baseUrl}/users`,
      {
        params:
        {
          nome: search?.nome || '',
          cognome: search?.cognome || '',
        }
      }
    ).pipe(
      map(val => val?.map(object => new User().deserialize(object)))

    );
  }

  getFile(user: User, key: any) {
    return this.http.get(`${this.baseUrl}/file/${user.id}`, {
      params: { key },
      responseType: 'blob',
    }).toPromise();
  }


  send_to_server_exported_users_ids_to_create_business_credentials(ids: number[]) {
    return this.http.post(`${this.baseUrl}/createGSuiteCreds`, { user_ids: ids }).toPromise();
  }
}