import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';

@Injectable()
export class AfterLoginService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.Token.loggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
  constructor(private Token: TokenService, private router: Router,) { }

}