import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { JarwisService } from 'src/app/services/jarwis.service';

@Component({
  selector: 'main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit {
  public error = null;

  constructor(
    private formBuilder: FormBuilder,
    private Jarwis: JarwisService,
    private toast: ToastrService,
    private router: Router,
  ) {
  }

  comuni;
  form: FormGroup;
  nazioni;
  user: User;

  async ngOnInit() {
    this.user = await this.Jarwis.profileUser();

    if (this.user.gsuite_email) {
      this.router.navigateByUrl('/profile');
    }

    this.nazioni = this.Jarwis.nazioni();
    this.comuni = this.Jarwis.comuni();

    this.form = this.formBuilder.group({
      id: [this.user.id],
      nome: [this.user.nome ?? null,],
      cognome: [this.user.cognome ?? null,],
      codice_fiscale: [this.user.codice_fiscale ?? null, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$')],
      genere: [this.user.genere ?? null,],
      data_di_nascita: [this.user.data_di_nascita ?? null,],
      cod_comune_nascita: [this.user.cod_comune_nascita ?? null,],
      provincia_di_nascita: [this.user.provincia_di_nascita ?? null],
      cod_stato_di_nascita: [this.user.cod_stato_di_nascita ?? null,],
      cod_cittadinanza: [this.user.cod_cittadinanza ?? null,],
      indirizzo_residenza: [this.user.indirizzo_residenza ?? null,],
      numero_civico_residenza: [this.user.numero_civico_residenza ?? null,],
      cap_residenza: [this.user.cap_residenza ?? null,],
      cod_comune_residenza: [this.user.cod_comune_residenza ?? null,],
      provincia_residenza: [this.user.provincia_residenza ?? null, Validators.compose([Validators.minLength(2), Validators.maxLength(2)]),],
      indirizzo_domicilio: [this.user.indirizzo_domicilio ?? null,],
      numero_civico_domicilio: [this.user.numero_civico_domicilio ?? null,],
      cap_domicilio: [this.user.cap_domicilio ?? null,],
      cod_comune_domicilio: [this.user.cod_comune_domicilio ?? null,],
      provincia_domicilio: [this.user.provincia_domicilio ?? null, Validators.compose([Validators.minLength(2), Validators.maxLength(2)]),],
      telefono: [this.user.telefono ?? null,],
      cellulare: [this.user.cellulare ?? null,],
      stato_civile: [this.user.stato_civile ?? 'nubile/celibe',],
      nome_coniuge: [this.user.nome_coniuge ?? null,],
      cognome_coniuge: [this.user.cognome_coniuge ?? null,],
      codice_fiscale_coniuge: [this.user.codice_fiscale_coniuge ?? null,],
      data_di_nascita_coniuge: [this.user.data_di_nascita_coniuge ?? null,],
      luogo_di_nascita_coniuge: [this.user.luogo_di_nascita_coniuge ?? null,],
      titolo_conseguito: [this.user.titolo_conseguito ?? null,],
      nome_titolo_conseguito: [this.user.nome_titolo_conseguito ?? null,],
      domicilioresidenza: [this.user.domicilioresidenza ?? 'No',],
      carta_identita: [this.user.carta_identita ?? null,],
      documento_codice_fiscale: [this.user.documento_codice_fiscale ?? null,],
      tipologia_contratto: [this.user.tipologia_contratto ?? null,],
      ore_contratto: [this.user.ore_contratto ?? null,],
      tipologia_servizio_svolto: [this.user.tipologia_servizio_svolto ?? null,],
      subentro: [this.user.subentro ?? "No"],
      iban: [this.user.iban ?? null, Validators.compose([Validators.minLength(27), Validators.maxLength(27)]),],
      patente: [this.user.patente ?? null,],
      titolo_di_studio: [this.user.titolo_di_studio ?? null,],
      busta_paga: [this.user.busta_paga],
    })
  }

  submitted = false;

  async onSubmit() {
    this.submitted = true;

    try {
      let result = await this.Jarwis.update(this.form.value);

      this.handleResponse(result);
    }

    catch (e) {
      console.error(e);
      this.handleError(e);
    }


  }

  handleResponse(res) {
    this.toast.success("Avete inviato correttamente i vostri dati all'ufficio del personale: riceverete presto una mail per la consegna delle credenziali dell'Account Aziendale e le modalità per la consegna dei documenti contrattuali.", 'Dati aggiornati', { timeOut: 30000 });
  }


  handleError(response) {
    this.error = response.errors || response.error?.errors;
    this.toast.warning("Controlla di aver riempito tutti i campi");
  }


  public onOptionsSelected(event) {  // se comune domicilio è uguale al comune di residenza
    const value = event.target.value;

    if (value == 'Si') {
      const fv = this.form.value;

      this.form.patchValue({
        indirizzo_domicilio: fv.indirizzo_residenza,
        numero_civico_domicilio: fv.numero_civico_residenza,
        cap_domicilio: fv.cap_residenza,
        cod_comune_domicilio: fv.cod_comune_residenza,
        provincia_domicilio: fv.provincia_residenza,
      });
    } else {
      this.form.patchValue({
        indirizzo_domicilio: [null,],
        numero_civico_domicilio: [null,],
        cap_domicilio: [null,],
        cod_comune_domicilio: [null,],
        provincia_domicilio: [null,],
      });
    }

  }


  public onOptionsSelectedCambioAppalto(event) {
    const value = event.target.value;
    if (value == 'No') {
      this.form.patchValue({
        tipologia_servizio_svolto: [null,],
        tipologia_contratto: [null,],
        titolo_di_studio: [null,],
        ore_contratto: [null,],
        busta_paga: [null,],
      });

    }
  }


  sendNewEmail() {
    this.Jarwis.sendEmailVerify().then(
      sent => this.toast.success("Mail inviata!")
    );
  }
}
