import { Component, Input, HostListener, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input('required') required;
  @Input('maxFilesNumber') maxFilesNumber = 1; //default 1 (non multiplo)
  @Input('validStatus') validStatus = !this.required;

  label: string = "Scegli il file da caricare";

  onChange: Function;
  public file: File | File[] = null;

  @HostListener('change', ['$event.target.files']) emitFiles(fileList: FileList) {
    let file: File | File[] = null;

    if (this.maxFilesNumber == 1) {
      file = fileList && fileList.item(0);
      this.label = fileList.item(0).name;
    }
    else {
      file = Array.from({ length: fileList.length }, (_, i) => fileList.item(i));
      this.label = file.reduce((prev, cur) => `${prev} ${cur.name}`, 'Files selezionati: ');
    }

    this.onChange(file);
    this.file = file;
  }

  constructor(private host: ElementRef<HTMLInputElement>) {
  }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }


  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }

}