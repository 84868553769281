import { Component, OnInit } from '@angular/core';
import { JarwisService } from './../../services/jarwis.service';
import { User } from './../../models/user';
import { Role } from './../../models/role'


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  UserProfile: User;
  Role: Role;

  constructor(
    public jarwisService: JarwisService,
  ) {
  }

  async ngOnInit() {
    this.UserProfile = await this.jarwisService.profileUser();
  }

}