<div class="mt-4 col-10 offset-1">
  <div class="card">
    <div class="card-header">
      <h3 class="mb-0">Registrazione</h3>
    </div>
    <div class="card-body">

      <h4>Benvenuti nel Portale Assunzioni della <b>Cooperativa Accento!</b></h4>

      <form *ngIf="form" [formGroup]="form">
        <h4>
          <b>
            Indicate la vostra <u>email personale</u> e scegliete una <u>password</u> che utilizzerete per accedere al
            Portale Assunzioni
          </b>
        </h4>
        <p>
          Verrà inviata una email di conferma per la creazione del vostro profilo inserimento dati.
        </p>
        <p>
          Cliccando sul link all'interno della email verrete reindirizzati a una pagina di inserimento dei dati
          necessari ad
          avviare il <u>processo di assunzione</u>.
        </p>
        <p>
          In breve tempo riceverete una mail al vostro indirizzo di email personale per la conferma dell'inizio del
          processo di assunzione, e saranno visibili nel vostro profilo sul Portale Assunzioni le <b>credenziali
            per accedere alla email aziendale e all'account gsuite</b>.
        </p>
        <div class="form-group row">
          <label for="inputEmail3" class="col-2 col-form-label">Email personale</label>
          <div class="col-10">
            <input type="email" class="form-control" placeholder="Email personale" formControlName="email" required>
            <div class="alert alert-danger" *ngIf="error?.email">
              {{error.email}}
            </div>


          </div>
        </div>



        <div class="form-group row">
          <label for="inputPassword3" class="col-2 col-form-label">Password</label>
          <div class="col-10">
            <input type="password" class="form-control" placeholder="Password" formControlName="password" required>
            <div class="alert alert-danger" *ngIf="error?.password">
              {{error.password}}
            </div>

          </div>
        </div>

        <div class="form-group row">
          <label for="inputPasswordConfirmation3" class="col-2 col-form-label">Conferma Password</label>
          <div class="col-10">
            <input type="password" class="form-control" placeholder="Conferma password"
              formControlName="password_confirmation" required>
            <div class="alert alert-danger" *ngIf="error?.password_confirmation">
              {{error.password_confirmation}}
            </div>
          </div>
        </div>

        <div class="form-group form-check">
          <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" required />
          <label for="acceptTerms" class="form-check-label">Accetto il trattamento dei miei dati come descritto <a
              href="https://www.coopaccento.it/informativa-privacy-accento/" target="_blank">qui</a> </label>
          <div *ngIf="error?.acceptTerms" class="alert alert-danger">Bisogna accettare il trattamento dei
            dati</div>

        </div>

        <button type="submit" (click)="onSubmit()" class="btn btn-primary">Registrati</button>


      </form>
    </div>
  </div>
</div>