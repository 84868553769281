<div class="container">
    <div class="card inner-main">
        <div class="card-header">
            Ricerca documenti di un utente
        </div>

        <div class="card-body">
            <div class="form-group row">
                <div class="col">
                    <form class="row" [formGroup]="form">
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Nome" formControlName="nome">
                        </div>
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Cognome" formControlName="cognome">
                        </div>
                    </form>

                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div *ngFor="let user of user$ | async">
                        <div class="row">
                            <div class="col-12">
                                <h3>Utente {{user.nome +' '+ user.cognome}}</h3>
                            </div>
                            <div class="col-12 mb-3 row">
                                <div class="col-6">
                                    <button [disabled]="!user.carta_identita" (click)="download(user,'carta_identita')"
                                        class="btn btn-primary">
                                        Carta d'identità
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button [disabled]="!user.documento_codice_fiscale"
                                        (click)="download(user,'documento_codice_fiscale')" class="btn btn-primary">
                                        Codice Fiscale
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 mb-3 row">
                                <div class="col-6">
                                    <button [disabled]="!user.titolo_di_studio"
                                        (click)="download(user,'titolo_di_studio')" class="btn btn-primary">
                                        Titolo di studio
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button [disabled]="!user.busta_paga_1" (click)="download(user,'busta_paga_1')"
                                        class="btn btn-primary">
                                        Busta paga 1
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 mb-3 row">
                                <div class="col-6">
                                    <button [disabled]="!user.busta_paga_2" (click)="download(user,'busta_paga_2')"
                                        class="btn btn-primary">
                                        Busta paga 2
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button [disabled]="!user.busta_paga_3" (click)="download(user,'busta_paga_3')"
                                        class="btn btn-primary">
                                        Busta paga 3
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>