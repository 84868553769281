import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

export class SelectValueBindingExample {
  selected = '1';
}
@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
  form: FormGroup;

  error: any;



  constructor(
    private formBuilder: FormBuilder,
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private toast: ToastrService,
  ) {

  }

  async onSubmit() {
    if (!this.form.valid)
      return this.toast.warning("Il trattamento dei dati personali deve essere accettato");

    try {
      let result = await this.Jarwis.signup(this.form.value);

      this.handleResponse(result);
    }

    catch (e) {
      console.error(e);
      this.handleError(e);
    }


  }

  handleResponse(data) {
    this.Token.handle(data.access_token);
    this.Auth.changeAuthStatus(true);
    this.router.navigateByUrl('/profile');
  }

  handleError(response) {
    this.error = response.errors || response.error.errors;
  }




  ngOnInit() {

    this.form = this.formBuilder.group(
      {
        email: [null,],
        password: [null,],
        password_confirmation: [null,],
        acceptTerms: [null, Validators.requiredTrue],
      }
    );

  }


}