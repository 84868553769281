<div class="container">
  <div class="card mt-3" *ngIf="UserProfile?.gsuite_email">
    <div class="card-header">
      Benvenuti nella <b>Cooperativa Accento</b>!
    </div>
    <div class="card-body">
      <h4>Queste sono le vostre credenziali per accedere all'account aziendale di GSuite.</h4>
      <div *ngIf="UserProfile?.gsuite_email">
        <p class="card-text">Email: <strong>{{ UserProfile?.gsuite_email }}</strong></p>
        <p class="card-text">Password: <strong>Accento2021!</strong></p>
        <p>
          Verrà richiesto di cambiare la password al primo accesso ad uno qualsiasi dei servizi della cooperativa.
        </p>
      </div>
      <div *ngIf="!UserProfile?.gsuite_email">
        <p class="card-text">
          Le credenziali aziendali non sono ancora state create dall'ufficio personale.
        </p>
      </div>

    </div>
  </div>
  <main-form *ngIf="UserProfile && !UserProfile.gsuite_email"></main-form>
</div>