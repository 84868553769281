export class Comuni{

    codice_comune: String;
    nome: String;

    deserialize(input): Comuni {
        let retV = new Comuni();
        Object.assign(retV, input);
    
        return retV;
      }
    


}