import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  private iss = {
    login: 'https://assunzioni.coopaccento.it/api/login',
    signup: 'https://assunzioni.coopaccento.it/api/signup',
    profile: 'https://assunzioni.coopaccento.it/api/profile',
  };

  constructor() { }

  handle(token) {
    this.set(token);
  }

  set(token) {
    localStorage.setItem('token', token);
  }
  get() {
    return localStorage.getItem('token');
  }

  remove() {
    localStorage.removeItem('token');
  }

  isValid() {
    try { //try getting the token and decoding it from base 64. if decoding fails, token is corrupted/missing
      const token = this.get();
      if (token) {
        const payload = this.payload(token);
        if (payload) {
          return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
        }
      }
      return false;
    }
    catch (e) {
      return false;
    }
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }

}