<div class="container-fluid">
  <div class="card mt-3">
    <div class="card-header">
      <h3>Esporta i dati degli utenti in un file excel e crea le loro credenziali aziendali</h3>
    </div>

    <div class="card-body">
      <h4>
        Nota bene: vengono mostrati solo gli utenti che non hanno già delle credenziali aziendali
      </h4>
      <div class="form-group row">
        <div class="col">
          <form class="row" [formGroup]="form">
            <label class="col-2 col-form-label">Data dalla quale si vuole fare l'export</label>
            <div class="col-10">
              <input type="date" class="form-control" placeholder="Data export" formControlName="start_from">
            </div>
          </form>
          <div class="col-12 text-right mt-4">

            <span class="mr-2">
              <label class="mr-2" for="creaCredenzialiAziendali"> Crea credenziali aziendali automaticamente al momento
                dell'esportazione </label>
              <input type="checkbox" id="creaCredenzialiAziendali" [ngModel]="creaCredenzialiAziendali">
            </span>

            <button [disabled]="!UsersProfile?.length || !size" (click)="exportexcel()" class="btn btn-success mr-2">
              Esporta excel
            </button>
            <button [disabled]="!UsersProfile?.length  || !size" (click)="exportcsv()" class="btn btn-success mr-2">
              Esporta csv
            </button>

            <button [disabled]="!UsersProfile?.length  || !size" (click)="deleteSelected()" class="btn btn-danger">
              Elimina utenti
            </button>

          </div>
          <div class="col-12">
            <div *ngIf="UsersProfile?.length==0">Nessun utente trovato.</div>
            <div *ngIf="UsersProfile?.length"> {{UsersProfile?.length}} utenti trovati in base alla ricerca.</div>
          </div>

        </div>
      </div>
      <table class="table table-striped" *ngIf="UsersProfile">
        <tr>
          <th>
            <input type="checkbox" title="Seleziona tutti" (change)="toggleSelectAll($event.target.checked)"
              #selectAllCheckbox>
          </th>
          <th>Cognome</th>
          <th>Nome</th>
          <th>Email</th>
          <th>Email Verificata</th>
          <th>Codice fiscale</th>
          <th>Data di nascita</th>
          <th>Genere</th>

        </tr>
        <tbody>
          <tr *ngFor="let UserProfile of UsersProfile">
            <td>
              <input #singleSelectionCheckbox type="checkbox" (change)="toggleSelected(UserProfile.id)">
            </td>
            <td> {{ UserProfile?.cognome }}</td> <!--  cognome-->
            <td> {{ UserProfile?.nome }}</td> <!-- nome -->
            <td> {{ UserProfile?.email }}</td> <!-- email -->
            <td> {{ UserProfile?.email_verified_at | date: 'dd/MM/yyyy' }}</td> <!-- email_verified_at -->
            <td> {{ UserProfile?.codice_fiscale| uppercase}}</td> <!-- codice_fiscale -->
            <td> {{ UserProfile?.data_di_nascita | date: 'dd/MM/yyyy'}}</td>
            <!-- data_nascita  il formato di esportazione è giusto, ma excel lo interpreta e sfancula la doppia digit del mese forzata...-->
            <td> {{ UserProfile?.genere | uppercase}}</td> <!-- sesso -->
          </tr>
        </tbody>
      </table>
      <p>
        {{size}} utenti selezionati
      </p>

      <div class="">

        <div class="col-12 text-right mt-4">

          <span class="mr-2">
            <span class="mr-2" for="creaCredenzialiAziendali"> Esporta XML per importazioni (non crea credenziali
              aziendali) </span>
          </span>

          <button [disabled]="!UsersProfile?.length || !size" (click)="exportxml()" class="btn btn-success mr-2">
            Esporta XML
          </button>

        </div>
      </div>
    </div>
  </div>
</div>


<table *ngIf="UsersProfile" id="excel-table" style="display: none;">
  <tr>
    <th>cognome</th>
    <th>nome</th>
    <th>etichetta</th>
    <th>codice_fiscale</th>
    <th>data_nascita</th>
    <th>genere</th>
    <th>cittadinanza_cod</th>
    <th>cittadinanza_des</th>
    <th>nazione_nascita_cod</th>
    <th>nazione_nascita_des</th>
    <th>comune_nascita_cod</th>
    <th>comune_nascita_des</th>
    <th>comune_nascita_prov</th>
    <th>comune_residenza_cod</th>
    <th>comune_residenza_des</th>
    <th>comune_residenza_prov</th>
    <th>comune_residenza_cap</th>
    <th>comune_residenza_indirizzo</th>
    <th>comune_residenza_civico</th>
    <th>comune_domicilio_cod</th>
    <th>comune_domicilio_des</th>
    <th>comune_domicilio_prov</th>
    <th>comune_domicilio_cap</th>
    <th>comune_domicilio_indirizzo</th>
    <th>comune_domicilio_civico</th>
    <th>email</th>
    <th>telefono</th>
    <th>cellulare</th>
    <th>contratto_ccnl_cod</th>
    <th>contratto_ccnl_des</th>
    <th>rapporto_lavoro_cod</th>
    <th>rapporto_lavoro_des</th>
    <th>codice_paghe</th>
    <th>data_assunzione</th>
    <th>data_cessazione</th>
    <th>data_inizio_decorrenza</th>
    <th>data_fine_decorrenza</th>
    <th>livello_cod</th>
    <th>livello_des</th>
    <th>filiale_cod</th>
    <th>filiale_des</th>
    <th>qualifica_cod</th>
    <th>qualifica_des</th>
    <th>sede_lavoro_cod</th>
    <th>sede_lavoro_des</th>
    <th>monte_ore_sett</th>
    <th>monte_ore_lunedi</th>
    <th>monte_ore_martedi</th>
    <th>monte_ore_mercoledi</th>
    <th>monte_ore_giovedi</th>
    <th>monte_ore_venerdi</th>
    <th>monte_ore_sabato</th>
    <th>monte_ore_domenica</th>
    <th>codice_rilevazione_presenze</th>
    <th>tipologia_socio_cod</th>
    <th>tipologia_socio_des</th>
    <th>data_associazione</th>
    <th>data_fine_associazione</th>
    <th>stato_civile_cod</th>
    <th>stato_civile_des</th>
    <th>iban</th>
    <th>attivo</th>
    <th>data_estrazione</th>

  </tr>
  <tr *ngFor="let UserProfile of filteredUsersProfiles">
    <td>{{ UserProfile?.cognome }}</td> <!--  cognome-->
    <td> {{ UserProfile?.nome }}</td> <!-- nome -->
    <td> </td> <!-- etichetta -->
    <td> {{ UserProfile?.codice_fiscale| uppercase}}</td> <!-- codice_fiscale -->
    <td> {{ UserProfile?.data_di_nascita | date: 'dd/MM/yyyy'}}</td>
    <!-- data_nascita  il formato di esportazione è giusto, ma excel lo interpreta e sfancula la doppia digit del mese forzata...-->
    <td> {{ UserProfile?.genere | uppercase}}</td> <!-- sesso -->
    <td> {{ UserProfile?.cod_cittadinanza}} </td> <!-- cittadinanza_cod -->
    <td> {{UserProfile?.nazione_cittadinanza?.nome}}</td> <!-- cittadinanza_des -->
    <td> {{ UserProfile?.cod_stato_di_nascita}} </td> <!-- nazione_nascita_cod -->
    <td> {{UserProfile?.nazione_nascita?.nome}} </td> <!-- nazione_nascita_des -->
    <td> {{ UserProfile?.cod_comune_nascita }} </td> <!-- comune_nascita_cod -->
    <td> {{UserProfile?.comune_nascita?.nome}}</td> <!-- comune_nascita_des -->
    <td> {{UserProfile?.provincia_di_nascita}} </td> <!-- comune_nascita_prov -->
    <td> {{ UserProfile?.cod_comune_residenza }}</td> <!-- comune_residenza_cod -->
    <td> {{UserProfile?.comune_residenza?.nome}}</td> <!-- comune_residenza_des -->
    <td> {{UserProfile?.provincia_residenza}}</td> <!-- comune_residenza_prov -->
    <td> {{UserProfile?.cap_residenza}}</td> <!-- comune_residenza_cap -->
    <td> {{UserProfile?.indirizzo_residenza}}</td> <!-- comune_residenza_indirizzo -->
    <td> {{UserProfile?.numero_civico_residenza}}</td> <!-- comune_residenza_civico -->
    <td> {{ UserProfile?.cod_comune_domicilio }}</td> <!-- comune_domicilio_cod -->
    <td> {{UserProfile?.comune_domicilio?.nome}}</td> <!-- comune_domicilio_des -->
    <td> {{UserProfile?.provincia_domicilio}}</td> <!-- comune_domicilio_prov -->
    <td> {{UserProfile?.cap_domicilio}}</td> <!-- comune_domicilio_cap -->
    <td> {{UserProfile?.indirizzo_domicilio}}</td> <!-- comune_domicilio_indirizzo -->
    <td> {{UserProfile?.numero_civico_domicilio}}</td> <!-- comune_domicilio_civico -->
    <td> {{UserProfile?.email}}</td> <!-- email -->
    <td> {{UserProfile?.telefono}}</td> <!-- telefono -->
    <td> {{UserProfile?.cellulare}}</td> <!-- cellulare -->
    <td> </td> <!-- contratto_ccnl_cod -->
    <td>{{UserProfile?.tipologia_contratto}}</td> <!-- contratto_ccnl_des -->
    <td> </td> <!-- rapporto_lavoro_cod -->
    <td></td> <!-- rapporto_lavoro_des -->
    <td></td> <!-- codice_paghe -->
    <td></td> <!-- data_assunzione -->
    <td></td> <!-- data_cessazione -->
    <td></td> <!-- data_inizio_decorrenza -->
    <td></td> <!-- data_fine_decorrenza -->
    <td></td> <!-- livello_cod -->
    <td></td> <!-- livello_des -->
    <td></td> <!-- filiale_cod -->
    <td></td> <!-- filiale_des -->
    <td></td> <!-- qualifica_cod -->
    <td></td> <!-- qualifica_des -->
    <td></td> <!-- sede_lavoro_cod -->
    <td></td> <!-- sede_lavoro_des -->
    <td></td> <!-- monte_ore_sett -->
    <td></td> <!-- monte_ore_lunedi -->
    <td></td> <!-- monte_ore_martedi -->
    <td></td> <!-- monte_ore_mercoledi -->
    <td></td> <!-- monte_ore_giovedi -->
    <td></td> <!-- monte_ore_venerdi -->
    <td></td> <!-- monte_ore_sabato -->
    <td></td> <!-- monte_ore_domenica -->
    <td></td> <!-- codice_rilevazione_presenze -->
    <td></td> <!-- tipologia_socio_cod -->
    <td></td> <!-- tipologia_socio_des -->
    <td></td> <!-- data_associazione -->
    <td></td> <!-- data_fine_associazione -->
    <td></td> <!-- stato_civile_cod -->
    <td>{{UserProfile?.stato_civile}}</td> <!-- stato_civile_des -->
    <td>{{UserProfile?.iban | uppercase}}</td> <!-- iban -->
    <td> </td> <!-- attivo -->
    <td></td> <!-- data_estrazione -->
  </tr>
</table>