import { Component, OnInit } from '@angular/core';
import { JarwisService } from './../../services/jarwis.service';
import { User } from './../../models/user';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private jarwisService: JarwisService,
    private fileSaver: FileSaverService,) { }

  user$: Observable<User[]>;
  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      nome: [],
      cognome: [],
    });

    this.form.valueChanges.subscribe(v => this.updateSearch())
    this.updateSearch();
  }

  updateSearch() {
    this.user$ = this.jarwisService.getUsers(this.form.value);
  }



  async download(user: User, key) {
    let res = await this.jarwisService.getFile(user, key);
    this.fileSaver.save(res, this.getName(user[key]));
  }

  getName(path) {
    return path.substring(path.lastIndexOf('/') + 1);
  }
}
