import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { JarwisService } from './../../services/jarwis.service';
import * as XLSX from 'xlsx';
import { User } from './../../models/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'exportcsv',
  templateUrl: './exportcsv.component.html',
  styleUrls: ['./exportcsv.component.scss']
})
export class ExportcsvComponent {

  UsersProfile: User[];

  form: FormGroup;
  constructor(public jarwisService: JarwisService,
    private toast: ToastrService,
    private fb: FormBuilder) {
    this.form = fb.group({
      start_from: [(new Date()).toISOString().substring(0, 10)],
    });

    this.form.valueChanges.subscribe(() => this.loadUsers());
  }

  async loadUsers() {
    this.UsersProfile = await this.jarwisService.exportCsv(this.form.value.start_from).toPromise();
    this.selectedUsers = new Set();
    this.updateSizeOfSelection();
  }

  creaCredenzialiAziendali = true;

  exportexcel(): void {
    let ws = this.getSheet();

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.getFileName());
    this.invia_al_server_utenti_esportati_per_la_creazione_delle_credenziali_aziendali()
  }

  exportcsv(): void {
    let ws = this.getSheet();
    let csv = XLSX.utils.sheet_to_csv(ws, {
      FS: ';',
    });

    saveAs(new Blob([csv]), this.getFileName('csv'));
    this.invia_al_server_utenti_esportati_per_la_creazione_delle_credenziali_aziendali()
  }

  getSheet() {
    let element = document.getElementById('excel-table');
    return XLSX.utils.table_to_sheet(element);
  }

  getFileName(ext = 'xlsx'): string {
    let name = `export-assunzioni-dal-${this.form.value.start_from}`;

    return name + '.' + ext;
  }


  get filteredUsersProfiles(): User[] {
    return this.UsersProfile.filter(
      u => this.isSelected(u.id)
    )
  }

  isSelected(id) {
    return this.selectedUsers.has(id);
  }

  selectedUsers = new Set<number>();

  toggleSelected(id) {
    if (this.selectedUsers.has(id))
      this.selectedUsers.delete(id);
    else
      this.selectedUsers.add(id);

    this.updateSizeOfSelection();
  }

  size = 0;
  updateSizeOfSelection() {
    this.size = this.selectedUsers.size;

    this.selectAllCheckbox.nativeElement.checked = this.size === this.UsersProfile.length;

  }

  @ViewChildren('singleSelectionCheckbox') singleSelectionCheckboxes: QueryList<ElementRef<HTMLInputElement>>;

  toggleSelectAll(checked) {
    if (checked) {
      for (let user of this.UsersProfile)
        this.selectedUsers.add(user.id);
      for (let ssc of this.singleSelectionCheckboxes.toArray())
        ssc.nativeElement.checked = true;
    }
    else {
      this.selectedUsers = new Set();
      for (let ssc of this.singleSelectionCheckboxes.toArray())
        ssc.nativeElement.checked = false;
    }

    this.updateSizeOfSelection();
  }

  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef<HTMLInputElement>;


  invia_al_server_utenti_esportati_per_la_creazione_delle_credenziali_aziendali() {
    if (!this.creaCredenzialiAziendali)
      return false;
    const ids = [...this.selectedUsers];

    let creatingTost = this.toast.info("Creazione credenziali aziendali in corso...", "Attendi", { disableTimeOut: true });

    this.jarwisService.send_to_server_exported_users_ids_to_create_business_credentials(ids).then(
      res => {
        this.toast.remove(creatingTost.toastId);
        this.toast.success("Credenziali create");
      },
      error => {
        console.error(error);
        this.toast.remove(creatingTost.toastId);
        this.toast.error("La creazione delle credenziali non è andata a buon fine.");
      }
    )


  }

  async deleteSelected() {
    let confirmation = confirm("Sei sicuro di voler eliminare gli utenti selezionati?");
    if (!confirmation) {
      this.toast.success("Gli utenti non sono stati eliminati");
      return 0;
    }

    let selectedIds = [...this.selectedUsers];

    try {
      await this.jarwisService.delete(selectedIds);
      this.toast.success(selectedIds.length + " righe sono state eliminate");
    }
    catch (e) {
      this.toast.error("Errore durante la eliminazione");
    }
    this.loadUsers();
  }

  exportxml() {
    let xml = this.getXml();

    saveAs(new Blob([xml]), this.getFileName("xml"));
  }

  private getXml() {
    let xml = `<?xml version="1.0" encoding="utf-8"?>\n`;

    for (let user of this.filteredUsersProfiles) {
      xml = xml + this.getXmlRepresentation(user) + "\n";
    }

    return xml;
  }

  getXmlRepresentation(user: User) {
    return `<DatiLavoratore>
      <AnagraficaCompleta>
        <codiceFiscale>${user.codice_fiscale}</codiceFiscale>
        <cognome>${user.cognome}</cognome>
        <nome>${user.nome}</nome>
        <sesso>${user.genere}</sesso>
        <dataNascita>${this.formatDateYYYY_MM_DD(user.data_di_nascita)}</dataNascita>
        <comuneNascita>${user.cod_comune_nascita}</comuneNascita>
        <cittadinanza>${user.cod_cittadinanza}</cittadinanza>
      </AnagraficaCompleta>
      <IndirizzoDomicilio>
        <Comune>${user.cod_comune_domicilio ?? user.cod_comune_residenza}</Comune>
        <cap>${user.cap_domicilio ?? user.cap_residenza}</cap>
        <Indirizzo>${user.indirizzo_domicilio ?? user.indirizzo_residenza}</Indirizzo>
      </IndirizzoDomicilio>
      <LivelloIstruzione>83</LivelloIstruzione>
    </DatiLavoratore>`;
    //todo: fix livello istruzione mocked
  }

  formatDateYYYY_MM_DD(date?: Date) {
    if (typeof date == "string")
      date = new Date(date);
    return date?.toISOString()?.slice(0, 10);
  }
}
