<div class="mt-4 col-8 offset-2">
    <div class="card">
      <div class="card-header">Reset Password</div>
      <div class="card-body">
        <form #ResetPasswordForm=ngForm (ngSubmit)="onSubmit()">
  
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
              <input type="email" name="email" class="form-control" id="inputEmail3" placeholder="Email" [(ngModel)]="form.email" required>
              <span class="text-danger" *ngIf="error.email">{{error.email}}</span>
            </div>
          </div>
  
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
            <div class="col-sm-10">
              <input type="password" name="password" class="form-control" id="inputPassword3" placeholder="Password" [(ngModel)]="form.password"
                required>
                <span class="text-danger" *ngIf="error.password">{{error.password}}</span>
            </div>
          </div>
          
          <div class="form-group row">
            <label for="inputPassword4" class="col-sm-2 col-form-label">Confirm Password</label>
            <div class="col-sm-10">
              <input type="password" name="password_confirmation" class="form-control" id="inputPassword4" placeholder="Confirm Password" [(ngModel)]="form.password_confirmation"
                required>
                <span class="text-danger" *ngIf="error.password_confirmation">{{error.password_confirmation}}</span>
            </div>
          </div>
  
          <div class="form-group row">
            <div class="col-sm-10 offset-2">
  
              <button type="submit" class="btn btn-primary" [disabled]="!ResetPasswordForm.valid">Change Password</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>