<div class="mt-4 col-8 offset-2">
    <div class="card">
      <div class="card-header">Accedi</div>
      <div class="card-body">
        <form #loginForm=ngForm (ngSubmit)="onSubmit()">
  
          <div class="alert alert-danger" [hidden]="!error">
            {{error}}
          </div>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
              <input type="email" name="email" class="form-control" id="inputEmail3" placeholder="Email" [(ngModel)]="form.email" required>
            </div>
          </div>
  
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
            <div class="col-sm-10">
              <input type="password" name="password" class="form-control" id="inputPassword3" placeholder="Password" [(ngModel)]="form.password"
                required>
            </div>
          </div>
  
          <div class="form-group row">
            <div class="col-sm-10 offset-2">
  
              <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Accedi</button>
  
              <a routerLink="/signup" class="btn btn-info float-right">Registrati</a>
  
            </div>
          </div>
          <small class=float-right>
            <a routerLink="/request-password-reset">Recupero Password</a>
          </small>
        </form>
      </div>
    </div>
  </div>