export class Nazione {

  codice_nazione: String;
  nome: String;

  deserialize(input): Nazione {
    let retV = new Nazione();
    Object.assign(retV, input);

    return retV;
  }



}