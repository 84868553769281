import { Comuni } from './comuni';
import { Nazione } from './nazione';
import { Role } from './role';

export class User {
  id: number;
  gsuite_email: String;
  password: String;
  email_verified_at;
  email;
  nome: String;
  cognome: String;
  codice_fiscale: String;
  genere: String;
  cod_cittadinanza: String;
  provincia_di_nascita: String;
  nazione_cittadinanza: Nazione;
  cod_stato_di_nascita: String;
  stato_di_nascita: Nazione;
  data_di_nascita: Date;
  cod_comune_nascita: String;
  comune_nascita: Comuni;
  indirizzo_residenza: String;
  numero_civico_residenza: String;
  cap_residenza: String;
  cod_comune_residenza: String;
  comune_residenza: Comuni;
  provincia_residenza: String;
  indirizzo_domicilio: String;
  numero_civico_domicilio: String;
  cod_comune_domicilio: String;
  comune_domicilio: Comuni;
  cap_domicilio: String;
  provincia_domicilio: String;
  telefono: String;
  cellulare: String;
  stato_civile: String;
  nome_coniuge: String;
  cognome_coniuge: String;
  codice_fiscale_coniuge: String;
  data_di_nascita_coniuge: String;
  luogo_di_nascita_coniuge: String;
  titolo_conseguito: String;
  nome_titolo_conseguito: String;
  carta_identita: String;
  documento_codice_fiscale: String;
  tipologia_contratto: String;
  ore_contratto: String;
  iban: String;
  tipologia_servizio_svolto: String;
  patente: String;
  roles: Role[];


  deserialize(input): User {
    let retV = new User();
    Object.assign(retV, input);
    if (input?.roles) {
      retV.roles = input.roles.map(role => new Role().deserialize(role));
    }


    return retV;
  }

  hasRole(searchedRoleName: string) {
    if (Array.isArray(this.roles)) {
      return this.roles.some(v => v.nome == searchedRoleName)
    }
    return false;

  }

  get subentro(): string {
    return !!(this.ore_contratto || this.tipologia_servizio_svolto || this.tipologia_contratto) ? "Si" : "No";
  }

  busta_paga_1;
  busta_paga_2;
  busta_paga_3;

  get busta_paga(): boolean {
    return !!(this.busta_paga_1 || this.busta_paga_2 || this.busta_paga_3);
  }

  titolo_di_studio: string;


  get domicilioresidenza(): string {
    if (this.cap_domicilio || this.comune_domicilio || this.indirizzo_domicilio || this.provincia_domicilio || this.numero_civico_domicilio)
      return 'No'; //se uno di questi valori è truthy, il domicilio è valorizzato quindi il domicilio NON è uguale alla residenza
    return 'Si'; // se tutti i campi non hanno valore allora il domicilio è uguale alla residenza
  }

}