<nav class="navbar navbar-light bg-light justify-content-between">
  <a class="navbar-brand">Assunzioni Accento</a>
  <div>
    <a class="navbar-brand" routerLink="login" *ngIf="!loggedIn">Accedi</a>
    <a class="navbar-brand" href="#" (click)="logout($event)" *ngIf="loggedIn">Logout</a>
    <a class="navbar-brand" routerLink="profile" *ngIf="loggedIn">Profilo</a>
    <a class="navbar-brand" routerLink="exportcsv" *ngIf="loggedIn && UserProfile?.hasRole('admin')">Esporta CSV</a>
    <a class="navbar-brand" routerLink="documents" *ngIf="loggedIn  && UserProfile?.hasRole('admin')">Documenti</a>
  </div>

</nav>