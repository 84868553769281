<div class="mt-4 col-10 offset-1">
    <ng-container *ngIf="user">
        <ng-container *ngIf="user.email_verified_at; else verify">
            <div class="card">
                <div class="card-header">
                    <h3>Inserire le informazioni necessarie all'Ufficio Personale per procedere con l'assunzione.</h3>
                </div>
                <div *ngIf="form" class="card-body">
                    <form [formGroup]="form">
                        <div class="form-group row">
                            <label class="col-2 col-form-label">Email</label>
                            <div class="col-10">
                                <input type="text" class="form-control" readonly [value]="user.email">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputName3" class="col-2 col-form-label">Nome</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Nome" formControlName="nome"
                                    required>
                                <div class="alert alert-danger" *ngIf="error?.nome">
                                    {{error.nome}}
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputCognome3" class="col-2 col-form-label">Cognome</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Cognome" formControlName="cognome"
                                    required>
                                <div class="alert alert-danger" *ngIf="error?.cognome">
                                    {{error.cognome}}
                                </div>

                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputCodiceFiscale3" class="col-2 col-form-label">Codice fiscale</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Codice Fiscale"
                                    (blur)="$event.target.value = $event.target.value.toUpperCase()"
                                    formControlName="codice_fiscale"
                                    [ngClass]="{ 'is-invalid': form?.get('codice_fiscale')?.hasError('pattern')  }"
                                    required>
                                <div *ngIf="form?.get('codice_fiscale')?.hasError('pattern')" class="invalid-feedback">
                                    Codice
                                    fiscale non
                                    corretto</div>
                                <div class="alert alert-danger" *ngIf="error?.codice_fiscale">
                                    {{error.codice_fiscale}}
                                </div>

                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputGenere3" class="col-2 col-form-label">Genere</label>
                            <div class="col-10">
                                <select class="form-control" class="form-control" placeholder="Genere"
                                    formControlName="genere" required>
                                    <option>m</option>
                                    <option>f</option>

                                </select>
                                <div class="alert alert-danger" *ngIf="error?.genere">
                                    {{error.genere}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputDataDiNascita3" class="col-2 col-form-label">Data di nascita</label>
                            <div class="col-10">
                                <input type="date" class="form-control" placeholder="Data di nascita"
                                    formControlName="data_di_nascita" required>
                                <div class="alert alert-danger" *ngIf="error?.data_di_nascita">
                                    {{error.data_di_nascita}}
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputCodComuneNascita3" class="col-2 col-form-label">Luogo di nascita</label>
                            <div class="col-10">
                                <ng-select [virtualScroll]="true" [items]="comuni | async" bindLabel="nome"
                                    bindValue="codice_comune" formControlName="cod_comune_nascita">
                                </ng-select>
                                <div class="alert alert-danger" *ngIf="error?.cod_comune_nascita">
                                    {{error.cod_comune_nascita}}
                                </div>
                                <p>
                                    Nel caso di luogo di nascita in paese estero, selezionare la voce: "Estero"
                                </p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputProvinciaDiNascita3" class="col-2 col-form-label">Provincia di nascita
                                (sigla)</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Esempio: MO, BO, RE, ..."
                                    formControlName="provincia_di_nascita"
                                    [ngClass]="{ 'is-invalid': form?.getError('provincia_di_nascita')  }">
                                <p>
                                    Lasciare vuoto in caso di luogo di nascita in paese estero
                                </p>
                                <div *ngIf="form?.getError('provincia_di_nascita')" class="invalid-feedback">
                                    Devi inserire la sigla della provincia
                                </div>
                                <div class="alert alert-danger" *ngIf="error?.provincia_di_nascita">
                                    {{error.provincia_di_nascita}}
                                </div>

                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputCodStatoDiNascita3" class="col-2 col-form-label">Stato di nascita</label>
                            <div class="col-10">
                                <select class="form-control" formControlName="cod_stato_di_nascita" required>
                                    <option [value]="n?.codice_nazione" *ngFor="let n of nazioni | async">
                                        {{n?.nome}}
                                    </option>
                                </select>
                                <div class="alert alert-danger" *ngIf="error?.cod_stato_di_nascita">
                                    {{error.cod_stato_di_nascita}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputCodCittadinanza3" class="col-2 col-form-label">Cittadinanza</label>
                            <div class="col-10">
                                <select class="form-control" formControlName="cod_cittadinanza" required>
                                    <option [value]="n?.codice_nazione" *ngFor="let n of nazioni | async">
                                        {{n?.nome}}
                                    </option>
                                </select>
                                <div class="alert alert-danger" *ngIf="error?.cod_cittadinanza">
                                    {{error.cod_cittadinanza}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputIndirizzoResidenza3" class="col-2 col-form-label">Indirizzo
                                residenza</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Indirizzo residenza"
                                    formControlName="indirizzo_residenza" required>
                                <div class="alert alert-danger" *ngIf="error?.indirizzo_residenza">
                                    {{error.indirizzo_residenza}}
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputNumeroCivicoResidenza3" class="col-2 col-form-label">Numero civico
                                residenza</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Numero civico residenza"
                                    formControlName="numero_civico_residenza" required>
                                <div class="alert alert-danger" *ngIf="error?.numero_civico_residenza">
                                    {{error.numero_civico_residenza}}
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputCapResidenza3" class="col-2 col-form-label">Cap residenza</label>
                            <div class="col-10">
                                <input type="number" class="form-control" placeholder="Cap residenza"
                                    formControlName="cap_residenza" required>
                                <div class="alert alert-danger" *ngIf="error?.cap_residenza">
                                    {{error.cap_residenza}}
                                </div>
                            </div>
                        </div>


                        <div class="form-group row">
                            <label for="inputCodComuneResidenza3" class="col-2 col-form-label">Comune residenza</label>
                            <div class="col-10">
                                <ng-select [virtualScroll]="true" [items]="comuni | async" bindLabel="nome"
                                    bindValue="codice_comune" formControlName="cod_comune_residenza">
                                </ng-select>
                                <div class="alert alert-danger" *ngIf="error?.cod_comune_residenza">
                                    {{error.cod_comune_residenza}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputProvinciaResidenza3" class="col-2 col-form-label">Provincia residenza
                                (sigla)</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Esempio: MO, BO, RE, ..."
                                    formControlName="provincia_residenza"
                                    [ngClass]="{ 'is-invalid': form?.getError('provincia_di_nascita')  }" required>
                                <div *ngIf="form?.getError('provincia_di_nascita')" class="invalid-feedback">
                                    Devi inserire la sigla della provincia
                                </div>
                                <div class="alert alert-danger" *ngIf="error?.provincia_residenza">
                                    {{error.provincia_residenza}}
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputDomicilioResidenza3" class="col-2 col-form-label">Il domicilio è uguale
                                alla
                                residenza?</label>
                            <div class="col-10">
                                <select class="custom-select" (change)='onOptionsSelected($event)'
                                    formControlName="domicilioresidenza" id="inputDomicilioResidenza3" required>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>

                        <div *ngIf="form?.value?.domicilioresidenza == 'No'">
                            <div class="form-group row">
                                <label for="inputIndirizzoDomicilio3" class="col-2 col-form-label">Indirizzo
                                    domicilio</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Indirizzo domicilio"
                                        formControlName="indirizzo_domicilio" required>

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputNumeroCivicoDomicilio3" class="col-2 col-form-label">Numero civico
                                    domicilio</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Numero civico domicilio"
                                        formControlName="numero_civico_domicilio" required>

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputCapDomicilio3" class="col-2 col-form-label">Cap domicilio</label>
                                <div class="col-10">
                                    <input type="number" class="form-control" placeholder="Cap domicilio"
                                        formControlName="cap_domicilio" required>

                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputCodComuneDomicilio3" class="col-2 col-form-label">Comune
                                    domicilio</label>
                                <div class="col-10">
                                    <ng-select [virtualScroll]="true" [items]="comuni | async" bindLabel="nome"
                                        bindValue="codice_comune" formControlName="cod_comune_domicilio">
                                    </ng-select>
                                    <div class="alert alert-danger" *ngIf="error?.cod_comune_domicilio">
                                        {{error.cod_comune_domicilio}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputProvinciaDomicilio3" class="col-2 col-form-label">Provincia domicilio
                                    (sigla)</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Esempio: MO, BO, RE, ..."
                                        formControlName="provincia_domicilio" required>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputProvinciaTelefono3" class="col-2 col-form-label">Telefono</label>
                            <div class="col-10">
                                <input type="tel" class="form-control" placeholder="Telefono" formControlName="telefono"
                                    required>
                                <div class="alert alert-danger" *ngIf="error?.telefono">
                                    {{error.telefono}}
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputProvinciaCellulare3" class="col-2 col-form-label">Cellulare</label>
                            <div class="col-10">
                                <input type="tel" class="form-control" placeholder="Cellulare"
                                    formControlName="cellulare" required>
                                <div class="alert alert-danger" *ngIf="error?.cellulare">
                                    {{error.cellulare}}
                                </div>

                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="inputPatente3" class="col-2 col-form-label">Tipo patente</label>
                            <div class="col-10">
                                <select class="form-control" class="form-control" formControlName="patente" required>
                                    <option>A</option>
                                    <option>B</option>
                                    <option>C</option>
                                    <option>Non ho la patente</option>
                                </select>
                                <div class="alert alert-danger" *ngIf="error?.patente">
                                    {{error.patente}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputIban3" class="col-2 col-form-label">IBAN</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="IBAN (27 lettere)"
                                    formControlName="iban" [ngClass]="{ 'is-invalid': form?.getError('iban')  }"
                                    required>
                                <div *ngIf="form?.getError('iban')" class="invalid-feedback">
                                    L'IBAN deve avere 27 lettere
                                </div>
                                <div class="alert alert-danger" *ngIf="error?.iban">
                                    {{error.iban}}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!user.carta_identita" class="form-group row">

                            <label for="inputNomeCartaIdentita3" class="col-2 col-form-label">Carica carta
                                identità</label>
                            <div class="col-10">
                                <app-file-upload [validStatus]="form?.get('carta_identita')?.valid"
                                    formControlName="carta_identita" required="true" multiple="true"></app-file-upload>
                            </div>
                        </div>
                        <div *ngIf="!user.documento_codice_fiscale" class="form-group row">

                            <label for="inputDocumentoCodiceFiscale3" class="col-2 col-form-label">Carica codice
                                fiscale</label>
                            <div class="col-10">
                                <app-file-upload [validStatus]="form?.get('documento_codice_fiscale')?.valid"
                                    formControlName="documento_codice_fiscale" required="true" multiple="true">
                                </app-file-upload>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputStatoCivile3" class="col-2 col-form-label">Stato civile</label>
                            <div class="col-10">
                                <select class="form-control" formControlName="stato_civile" required>
                                    <option selected>nubile/celibe</option>
                                    <option>coniugata/o</option>
                                    <option>separata/o</option>
                                    <option>divorziata/o</option>
                                    <option>vedova/o</option>
                                </select>
                                <div class="alert alert-danger" *ngIf="error?.stato_civile">
                                    {{error.stato_civile}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="form?.value?.stato_civile !== 'nubile/celibe'">
                            <div class="form-group row">
                                <label for="inputNameConiuge3" class="col-2 col-form-label">Nome Coniuge</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Name Coniuge"
                                        formControlName="nome_coniuge">

                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputCognomeConiuge3" class="col-2 col-form-label">Cognome Coniuge</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Cognome Coniuge"
                                        formControlName="cognome_coniuge">

                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputCodiceFiscaleConiuge3" class="col-2 col-form-label">Codice
                                    fiscale</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Codice Fiscale Coniuge"
                                        formControlName="codice_fiscale_coniuge">

                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputDataDiNascitaConiuge3" class="col-2 col-form-label">Data di nascita
                                    Coniuge</label>
                                <div class="col-10">
                                    <input type="date" class="form-control" placeholder="Data di nascita Coniuge"
                                        formControlName="data_di_nascita_coniuge">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputLuogoDiNascitaConiuge3" class="col-2 col-form-label">Luogo di nascita
                                    Coniuge</label>
                                <div class="col-10">
                                    <input type="text" class="form-control" placeholder="Luogo di nascita Coniuge"
                                        formControlName="luogo_di_nascita_coniuge">

                                    <p>
                                        Nel caso di luogo di nascita in paese estero, selezionare la voce: "Estero"
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputTitoloConseguito3" class="col-2 col-form-label">Titolo Conseguito</label>
                            <div class="col-10">
                                <select class="form-control" class="form-control" formControlName="titolo_conseguito"
                                    required>
                                    <option>licenza media</option>
                                    <option>qualifica</option>
                                    <option>diploma maturita</option>
                                    <option>laurea</option>

                                </select>
                                <div class="alert alert-danger" *ngIf="error?.titolo_conseguito">
                                    {{error.titolo_conseguito}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputNomeTitoloConseguito3" class="col-2 col-form-label">Nome titolo
                                conseguito</label>
                            <div class="col-10">
                                <input type="text" class="form-control" placeholder="Nome titolo conseguito"
                                    formControlName="nome_titolo_conseguito" required>
                                <div class="alert alert-danger" *ngIf="error?.nome_titolo_conseguito">
                                    {{error.nome_titolo_conseguito}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputSubentro3" class="col-2 col-form-label">Assunzione per cambio
                                appalto</label>
                            <div class="col-10">
                                <select class="custom-select" (change)='onOptionsSelectedCambioAppalto($event)'
                                    id="inputSubentro3" formControlName="subentro" required>
                                    <option value="Si">Si</option>
                                    <option value="No" selected>No</option>

                                </select>
                            </div>

                        </div>


                        <div *ngIf="form?.value?.subentro == 'Si'">
                            <div class="form-group row">
                                <label for="inputTipologiaServizioSvolto3" class="col-2 col-form-label">Tipologia
                                    servizio
                                    svolto</label>
                                <div class="col-10">
                                    <select class="form-control" class="form-control"
                                        placeholder="Tipologia servizio svolto"
                                        formControlName="tipologia_servizio_svolto" required>
                                        <option>integrazione scolastica</option>
                                        <option>pre e post</option>
                                        <option>educatore nido</option>
                                        <option>educatore infanzia</option>
                                        <option>ausiliaria</option>
                                        <option>altro</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputTipologiaContratto3" class="col-2 col-form-label">Tipologia
                                    contratto</label>
                                <div class="col-10">
                                    <select class="form-control" class="form-control" placeholder="Tipologia contratto"
                                        formControlName="tipologia_contratto" required>
                                        <option>tempo determinato</option>
                                        <option>tempo indeterminato</option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="!user.titolo_di_studio" class="form-group row">

                                <label for="inputTitoloDiStudio3" class="col-2 col-form-label">Titolo di studio</label>
                                <div class="col-10">
                                    <app-file-upload [validStatus]="form?.get('titolo_di_studio')?.valid"
                                        formControlName="titolo_di_studio" multiple="true" required="true">
                                    </app-file-upload>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputOreContratto3" class="col-2 col-form-label">Ore settimanali</label>
                                <div class="col-10">
                                    <input type="number" class="form-control" placeholder="Ore settimanali"
                                        formControlName="ore_contratto" required>

                                </div>
                            </div>
                            <div *ngIf="!user.busta_paga" class="form-group row">

                                <label for="inputBustaPaga3" class="col-2 col-form-label">Ultime 3 buste paga</label>
                                <div class="col-10">
                                    <app-file-upload [validStatus]="form?.get('busta_paga')?.valid"
                                        formControlName="busta_paga" required="true" maxFilesNumber="3">
                                    </app-file-upload>
                                </div>
                            </div>

                        </div>

                    </form>

                    <h4 class="mb-1">
                        Dopo la conferma i dati inseriti verranno inviati all'ufficio personale, che provvederà a
                        generare le credenziali di accesso alla vostra mail aziendale e al sistema di DAD GSuite.
                        <br>
                        Le credenziali saranno disponibili accedendo nuovamente a questa pagina.
                    </h4>
                    <button type="submit" (click)="onSubmit()" class="btn btn-primary">Invia</button>

                </div>
            </div>
        </ng-container>
        <ng-template #verify>
            <div class="card">
                <div class="card-header">
                    Verifica Email
                </div>
                <div class="card-body">
                    Abbiamo inviato un link per la verifica al tuo indirizzo email personale ({{user.email}}).
                    Se non hai ricevuto alcun link, controlla la cartella dello spam o posta indesiderata.
                    <br>
                    Altrimenti, invia un altra email:
                    <br>
                    <button class="mt-2 btn btn-primary" (click)="sendNewEmail()">
                        Invia nuova mail
                    </button>
                </div>

            </div>

        </ng-template>
    </ng-container>
</div>