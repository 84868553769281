import { Component, Input, HostListener, ElementRef, AfterViewInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { JarwisService } from 'src/app/services/jarwis.service';
import { Comuni } from '../../models/comuni';
@Component({
  selector: 'comune-picker',
  templateUrl: './comune-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComunePickerComponent),
      multi: true
    }
  ],
})
export class ComunePickerComponent implements ControlValueAccessor, AfterViewInit {

  form: FormGroup;
  protected destroy: Subject<void> = new Subject();

  constructor(private host: ElementRef<HTMLInputElement>,
    private fb: FormBuilder,
    private comuniService: JarwisService) {
    this.form = this.fb.group({
      comune: [null, Validators.required],
    });

  }

  ngAfterViewInit() {
    this.updateSearch();
  }


  comuni: Observable<Comuni[]>;

  updateSearch(search = "") {
    this.comuni = this.comuniService.comuni(search);
  }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
  }

  customChangeEmitter = new Subject<any>();
  customChange(comune) {
    this.form.patchValue({
      comune: comune,
    });
    this.customChangeEmitter.next(this.form.value.comune);
  }


  registerOnChange(fn) {
    this.customChangeEmitter.subscribe(fn);
  }

  registerOnTouched(fn) {
  }

}