<div class="custom-file" [ngClass]="{
    'custom-file':true,
    'ng-invalid':!validStatus,
    'ng-valid':validStatus
}">
    <input type="file" class="custom-file-input" [required]="required" [multiple]="maxFilesNumber>1">
    <label class="custom-file-label" for="validatedCustomFile">
        {{ label }}
    </label>
    <div class="invalid-feedback">File non valido</div>
</div>