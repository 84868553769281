import { User } from './user';

export class Role {
    nome: String;
    users: User[];


    deserialize(input): Role {
        let retV = new Role();

        Object.assign(retV, input);

        if (input.users) {
            retV.users = input.users.map(user => new User().deserialize(user));
        }

        return retV;
    }
}